import { Controller } from "stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = [ "source", "content", "arrow" ]

  connect() {
    if (this.isActive) {
      this.createPopperInstance();
    } else {
      this.destroyPopperInstance();
    }
  }

  disconnect() {
    this.destroyPopperInstance();
  }

  get popoverLayer() {
    return document.getElementById("page-layer-popover");
  }

  get isActive() {
    const value = this.data.get("active");
    if (typeof value === "undefined" || value === null) {
      return false;
    }
    return value.toLowerCase() === "true";
  }

  set isActive(value) {
    this.data.set("active", value ? "true" : "false");
  }

  get isArrowEnabled() {
    const value = this.data.get("arrowEnabled");
    if (typeof value === "undefined" || value === null) {
      return false;
    }
    return value.toLowerCase() === "true";
  }

  get placement() {
    return this.data.get("placement");
  }

  show() {
    this.isActive = true;
    this.createPopperInstance();
  }

  hide() {
    this.isActive = false;
    this.destroyPopperInstance();
  }

  toggle() {
    if (this.isActive) {
      this.hide();
    } else {
      this.show();
    }
  }

  createPopperInstance() {
    const options = {
      placement: this.placement,
      modifiers: [
        {
          name: "flip",
          enabled: true
        },
        {
          name: "offset",
          options: {
            offset: [0, 8]
          }
        }
      ]
    };

    if (this.isArrowEnabled) {
      if (!this.hasArrowTarget) {
        const arrow = document.createElement("div");
        arrow.classList.add("c-popover__arrow");
        arrow.dataset.target = "popover.arrow";
        this.contentTarget.appendChild(arrow);
      }

      options.modifiers.push({
        name: "arrow",
        options: {
          element: this.arrowTarget
        }
      });
    }

    this.contentNode = this.contentTarget;
    this.contentParentNode = this.contentTarget.parentNode;
    this.popoverLayer.appendChild(this.contentNode);

    this.popperInstance = createPopper(this.sourceTarget, this.contentNode, options);
    this.contentNode.classList.remove("is-hidden");
  }

  destroyPopperInstance() {
    this.contentNode?.classList.add("is-hidden");
    this.popperInstance?.destroy();
    this.popperInstance = null;
    this.contentParentNode?.appendChild(this.contentNode);
    this.contentParentNode = null;
    this.contentNode = null;
  }
}
