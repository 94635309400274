import { Controller } from "stimulus";

export default class extends Controller {
  highlight() {
    const targetIdentifier = this.data.get("targetIdentifier");
    Array.prototype.forEach.call(this.allHighlights, (el) => {
      if (String(el.dataset.jsHighlightIdentifier) === String(targetIdentifier)) {
        el.style.display = "";
      } else {
        el.style.display = "none";
      }
    });
  }

  dehighlight() {
    Array.prototype.forEach.call(this.allHighlights, (el) => {
      el.style.display = "";
    });
  }

  get allHighlights() {
    return document.querySelectorAll("[data-js-highlight-identifier]");
  }
}
