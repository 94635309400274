import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "priceContainer",
    "totalIpadCountInput",
    "rentedIpadCountInput",
    "extraFeaturesInput",
  ]

  connect() {
    this.reloadPrice();
  }

  get totalIpadCount() {
    if (this.hasTotalIpadCountInputTarget) {
      return this.totalIpadCountInputTarget.value || 0;
    } else {
      return 0;
    }
  }

  get rentedIpadCount() {
    if (this.hasRentedIpadCountInputTarget) {
      return this.rentedIpadCountInputTarget.value || 0;
    } else {
      return 0;
    }
  }

  get extraFeatures() {
    return this.extraFeaturesInputTargets.filter((el) => {
      return el.checked;
    }).map((el) => {
      return el.value;
    });
  }

  get queryUrl() {
    const params = this.extraFeatures.map((feature) => {
      return `${encodeURIComponent("event[extra_features][]")}=${encodeURIComponent(feature)}`;
    });
    if (params.length === 0) {
      params.push(`${encodeURIComponent("event[extra_features][]")}=`);
    }
    params.push(`${encodeURIComponent("event[total_ipad_count]")}=${encodeURIComponent(this.totalIpadCount)}`);
    params.push(`${encodeURIComponent("event[rented_ipad_count]")}=${encodeURIComponent(this.rentedIpadCount)}`);
    return `${this.data.get("url")}?${params.join("&")}`;
  }

  async reloadPrice() {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    const response = await fetch(this.queryUrl, { headers, credentials: "same-origin" });
    const text = await response.text();
    this.priceContainerTarget.innerHTML = text;
  }
}
