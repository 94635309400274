import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "item" ]

  initialize() {
    this.showCurrentItem();
  }

  connect() {
    this.tick();
  }

  disconnect() {
    if (typeof this.timerId !== "undefined" && this.timerId !== null) {
      clearTimeout(this.timerId);
    }
    this.timerId = null;
  }

  get index() {
    const _index = Number.parseInt(this.data.get("index"));
    return Number.isNaN(_index) ? 0 : _index;
  }

  set index(value) {
    this.data.set("index", value);
    this.showCurrentItem();
  }

  showCurrentItem() {
    this.itemTargets.forEach((el, i) => {
      if (this.index === i) {
        el.classList.add("c-preview__item--active");
      } else {
        el.classList.remove("c-preview__item--active");
      }
    });
  }

  tick() {
    const itemTargetsCount = this.itemTargets.length;
    if (itemTargetsCount > 1) {
      this.timerId = setTimeout(() => {
        this.index = (this.index + 1) % itemTargetsCount;
        this.tick();
      }, 3000);
    }
  }
}
