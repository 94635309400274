import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "displayContainer", "formContainer", "ignored" ]

  showEditForm(event) {
    if (this.ignoredTargets.includes(event.target)) {
      return;
    }

    event.preventDefault();

    const editFormLoader = document.createElement("div");
    editFormLoader.dataset.controller = "partial-loader";
    editFormLoader.dataset.partialLoaderUrl = this.data.get("url");
    editFormLoader.dataset.partialLoaderMinHeight = this.displayContainerTarget.clientHeight;

    this.formContainerTarget.appendChild(editFormLoader);

    this.formContainerTarget.style.display = "";
    this.displayContainerTarget.style.display = "none";

    const openEvent = new CustomEvent("guests--edit:open", { bubbles: true, detail: { source: this.element.id }});
    this.element.dispatchEvent(openEvent);
  }

  cancelEditForm() {
    this.formContainerTarget.style.display = "none";
    this.formContainerTarget.innerHTML = "";
    this.displayContainerTarget.style.display = "";
  }
}
