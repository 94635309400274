import Delta from "quill-delta";

class TemplateDecoder {
  static decode(str) {
    const delta = new Delta();
    this._parse(str).forEach((el) => {
      const token = el.match(/^::(\S+)::$/);
      if (token === null) {
        delta.insert(el);
      } else {
        delta.insert({ token: token[1] });
      }
    });
    delta.insert("\n");
    return delta;
  }

  static _parse(str) {
    return str.split(/(::[a-zA-Z0-9.]+::)/);
  }
}

class TemplateEncoder {
  static encode(delta) {
    const message = this._getContents(delta).join("");
    return this._removeNewlineIfNeeded(message);
  }

  static _getContents(delta) {
    return delta.map(this._getOperationContent.bind(this));
  }

  static _getOperationContent(op) {
    if (typeof op.insert === "string") {
      return op.insert;
    } else if (Object.prototype.hasOwnProperty.call(op.insert, "token")) {
      return `::${op.insert.token}::`;
    } else {
      return "";
    }
  }

  static _removeNewlineIfNeeded(str) {
    if (str.slice(-1) === "\n") {
      return str.slice(0, -1);
    } else {
      return str;
    }
  }
}

export { TemplateDecoder, TemplateEncoder };
