import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "pageOneContainer", "pageOneEndContainer", "locationSelect", "pageTwoContainer"]

  connect() {
    this.reloadPageTwo();
  }

  pageOneUrl(isDemo) {
    const baseUrl = this.data.get("page-one-url");
    return `${baseUrl}?${encodeURIComponent("event[demo]")}=${encodeURIComponent(isDemo)}`;
  }

  pageTwoUrl(location) {
    const baseUrl = this.data.get("page-two-url");
    return `${baseUrl}?${encodeURIComponent("event[location]")}=${encodeURIComponent(location)}`;
  }

  async isDemoSelected() {
    await this.reloadPageOne(true);
    this.destroyPageTwo();
  }

  async isNotDemoSelected() {
    await this.reloadPageOne(false);
    this.reloadPageTwo();
  }

  locationChanged() {
    this.reloadPageTwo();
  }

  showPageOne() {
    this.pageOneContainerTarget.style.display = "";
    this.pageTwoContainerTarget.style.display = "none";
  }

  showPageTwo() {
    this.pageOneContainerTarget.style.display = "none";
    this.pageTwoContainerTarget.style.display = "";
  }

  async reloadPageOne(isDemo) {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    const response = await fetch(this.pageOneUrl(isDemo), { headers, credentials: "same-origin" });
    const html = await response.text();
    this.pageOneEndContainerTarget.innerHTML = html;
    this.dispatchDidReloadEvent();
  }

  destroyPageTwo() {
    this.pageTwoContainerTarget.innerHTML = "";
    this.dispatchDidReloadEvent();
  }

  async reloadPageTwo() {
    if (this.hasLocationSelectTarget) {
      const headers = { "X-Requested-With": "XMLHttpRequest" };
      const response = await fetch(this.pageTwoUrl(this.locationSelectTarget.value), { headers, credentials: "same-origin" });
      const html = await response.text();
      this.pageTwoContainerTarget.innerHTML = html;
    } else {
      this.pageTwoContainerTarget.innerHTML = "";
    }
    this.dispatchDidReloadEvent();
  }

  dispatchDidReloadEvent() {
    console.log("did-reload");
    const didReloadEvent = new Event("events--new:did-reload");
    this.element.dispatchEvent(didReloadEvent);
  }
}
