import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "newContainer", "editContainer" ]

  closeOther(e) {
    const { source } = e.detail;
    if (source !== null) {
      const requestCloseEvent = new Event("guests--index:request-close");
      this.newContainerTarget.dispatchEvent(requestCloseEvent);
    }
    this.editContainerTargets.forEach((el) => {
      if (el.id !== source) {
        const requestCloseEvent = new Event("guests--index:request-close");
        el.dispatchEvent(requestCloseEvent);
      }
    });
  }
}
