import Dropzone from "dropzone";
import { openModal, closeModal } from "./modal";

let allDropzones = [];
let pollTimeoutID = null;

async function updateGuestlistKey(targetUrl, key) {
  const headers = {
    "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content"),
    "Content-Type": "application/json"
  };
  const body = JSON.stringify({ key });
  const response = await fetch(targetUrl, { method: "POST", headers, body, credentials: "same-origin" });
  const json = await response.json();
  return json.guest_import_processor_url;
}

function watchProcess(targetUrl, interval) {
  return new Promise((resolve, reject) => {
    (function poll() {
      pollTimeoutID = window.setTimeout(async function() {
        const response = await fetch(targetUrl, { credentials: "same-origin" });
        const json = await response.json();
        console.log(json.process_state);
        if (json.process_state === "COMPLETED") {
          resolve(json.download_url);
        } else if (json.process_state === "ERROR") {
          reject(new Error(json.error_message));
        } else {
          poll();
        }
      }, interval);
    })();
  });
}

async function onSuccess(targetUrl, key) {
  const processorUrl = await updateGuestlistKey(targetUrl, key);
  closeModal("uploading");
  openModal("processing");
  try {
    const downloadUrl = await watchProcess(processorUrl, 2000);

    closeModal("processing");
    openModal("done");
    console.log(`Import completed. ${downloadUrl}`);
  } catch (error) {
    closeModal("processing");
    openModal("error");
    console.log(`ERROR importing file: ${error.message}`);
  }
}

document.addEventListener("turbolinks:load", () => {
  Array.prototype.forEach.call(document.getElementsByClassName("js-guestlist-dropzone"), (el) => {
    const dropzone = new Dropzone(el, {
      autoQueue: false,
      createImageThumbnails: false,
      previewsContainer: false,
      acceptedFiles: el.dataset.jsAcceptedFiles,
      url: el.dataset.jsUploadUrl
    });
    dropzone.on("addedfile", async function(file) {
      const targetUrl = `${el.dataset.jsS3OptionsUrl}?doc[title]=${encodeURIComponent(file.name)}`;
      const response = await fetch(targetUrl, { credentials: "same-origin" });
      file.s3Options = await response.json();
      dropzone.enqueueFile(file);
    });
    dropzone.on("sending", (file, xhr, formData) => {
      openModal("uploading");

      const { key, policy, signature } = file.s3Options;
      formData.append("AWSAccessKeyId", el.dataset.jsS3AccessKeyId);
      formData.append("acl", "public-read");
      formData.append("key", key);
      formData.append("policy", policy);
      formData.append("signature", signature);
      formData.append("success_action_status", 201);
    });
    dropzone.on("complete", (file) => {
      dropzone.removeFile(file);
    });
    dropzone.on("success", (file) => {
      const uploadKey = file.xhr.responseXML.getElementsByTagName("Key")[0].textContent;
      onSuccess(el.dataset.jsUrl, uploadKey);
    });
    dropzone.on("error", (file, errorMessage, xhr) => {
      console.log(errorMessage);
      console.log(xhr);
    });
    allDropzones.push(dropzone);
  });
});

document.addEventListener("turbolinks:before-cache", () => {
  allDropzones.forEach((dropzone) => {
    dropzone.destroy();
  });
  allDropzones = [];

  if (pollTimeoutID !== null) {
    window.clearTimeout(pollTimeoutID);
    pollTimeoutID = null;
  }
});
