import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "showContainer", "editContainer" ]

  connect() {
    this.refresh();
  }

  get editUrl() {
    return this.data.get("editUrl");
  }

  get isEditEnabled() {
    return this.data.get("isEditEnabled") !== "false";
  }

  set isEditEnabled(value) {
    this.data.set("isEditEnabled", value.toString());
    this.refresh();
  }

  refresh() {
    if (this.isEditEnabled) {
      this.displayEditContainer();
    } else {
      this.displayShowContainer();
    }
  }

  displayShowContainer() {
    this.showContainerTarget.classList.add("is-active");
    this.editContainerTarget.classList.remove("is-active");

    this.editContainerTarget.innerHTML = "";
  }

  displayEditContainer() {
    this.showContainerTarget.classList.remove("is-active");
    this.editContainerTarget.classList.add("is-active");

    this.editContainerTarget.innerHTML = "";

    const editLoader = document.createElement("div");
    editLoader.dataset.controller = "partial-loader";
    editLoader.dataset.partialLoaderUrl = this.editUrl;
    editLoader.dataset.partialLoaderMinHeight = this.showContainerTarget.clientHeight;
    this.editContainerTarget.appendChild(editLoader);

    const openEvent = new CustomEvent("settings--cell:edit", { bubbles: true, detail: { source: this.element.id }});
    this.element.dispatchEvent(openEvent);
  }

  enableEdit() {
    this.isEditEnabled = true;
  }

  disableEdit() {
    this.isEditEnabled = false;
  }
}
