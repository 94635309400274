import { Controller } from "stimulus";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = [ "item" ]

  connect() {
    this.itemTargets.forEach((item) => {
      this.updateElementPositioning(item);
    });
  }

  onDragStart(event) {
    event.dataTransfer.setData("application/guestday", "Hello Drag");

    this.draggedElement = event.target;
    const dragPivot = this.mapCoordinate({ x: event.pageX, y: event.pageY });

    const xCoord = Number.parseFloat(this.draggedElement.dataset.jsPointerXCoord) || 0;
    const yCoord = Number.parseFloat(this.draggedElement.dataset.jsPointerYCoord) || 0;

    this.offset = { x: xCoord - dragPivot.x, y: yCoord - dragPivot.y };
    event.target.style.cursor = "grabbing";
  }

  onDragEnd(event) {
    event.target.style.cursor = "";
    this.draggedElement = null;
    this.offset = null;
  }

  onDragOver(event) {
    event.preventDefault();
  }

  async onDrop(event) {
    event.preventDefault();

    const coord = this.mapCoordinate({ x: event.pageX, y: event.pageY });
    const newCoordX = coord.x + this.offset.x;
    const newCoordY = coord.y + this.offset.y;

    this.draggedElement.dataset.jsPointerXCoord = newCoordX.toString();
    this.draggedElement.dataset.jsPointerYCoord = newCoordY.toString();

    this.updateElementPositioning(this.draggedElement);

    const updateUrl = this.draggedElement.dataset.jsPointerUpdateUrl;

    const headers = {
      "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content"),
      "Content-Type": "application/json"
    };
    const body = JSON.stringify({
      table: {
        x_coord: newCoordX,
        y_coord: newCoordY
      }
    });
    await fetch(updateUrl, { method: "PUT", headers, body, credentials: "same-origin" });
    Turbolinks.clearCache();
  }

  mapCoordinate(point) {
    const boundingRect = this.element.parentNode.getBoundingClientRect();

    const adjustedPosX = point.x - boundingRect.left - window.scrollX;
    const adjustedPosY = point.y - boundingRect.top - window.scrollY;

    const scaledPosX = Math.floor(adjustedPosX / 540 * 1620);
    const scaledPosY = Math.floor(adjustedPosY / 500 * 1500);

    return {
      x: scaledPosX,
      y: scaledPosY
    };
  }

  updateElementPositioning(item) {
    const xCoord = Number.parseFloat(item.dataset.jsPointerXCoord) || 0;
    const yCoord = Number.parseFloat(item.dataset.jsPointerYCoord) || 0;

    const left = xCoord * 100 / 1620;
    const top = yCoord * 100 / 1500;

    item.style.left = `calc(${left}% - 2rem)`;
    item.style.top = `calc(${top}% - 2rem)`;
    item.style.zIndex = 1001;
  }
}
