import Embed from "quill/blots/embed";

const translation = new Map([
  [ "salutation",         "Salutation"          ],
  [ "namePrefix",         "First Name"          ],
  [ "name",               "Last Name"           ],
  [ "nameSuffix",         "Additional Name"     ],
  [ "affiliation",        "Affiliation"         ],
  [ "actualAttendance",   "Actual Attendance"   ],
  [ "expectedAttendance", "Expected Attendance" ],
  [ "qrId",               "QR ID"               ],
  [ "qrKey",              "QR Key"              ],
  [ "table.name",         "Table Name"          ],
  [ "field1",             "Custom Field 1"      ],
  [ "field2",             "Custom Field 2"      ],
  [ "field3",             "Custom Field 3"      ],
  [ "field4",             "Custom Field 4"      ],
  [ "field5",             "Custom Field 5"      ],
  [ "field6",             "Custom Field 6"      ],
  [ "field7",             "Custom Field 7"      ],
  [ "fullName",           "Full Name"           ]
]);

class TokenBlot extends Embed {
  static create(value) {
    const node = super.create();
    node.classList.add("c-editor__token");
    const trimmedValue = value.trim();
    node.dataset.tokenBlotValue = trimmedValue;
    const translatedValue = translation.get(trimmedValue) || trimmedValue;
    node.textContent = translatedValue;
    return node;
  }

  static value(node) {
    return node.dataset.tokenBlotValue;
  }
}
TokenBlot.blotName = "token";
TokenBlot.tagName = "span";

export { TokenBlot };
