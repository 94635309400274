import { Controller } from "stimulus";
import Dropzone from "dropzone";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = [ "clickable" ]

  connect() {
    this.dropzoneInstance = new Dropzone(this.element, {
      createImageThumbnails: false,
      previewsContainer: false,
      acceptedFiles: this.data.get("acceptedFiles"),
      clickable: this.clickElement,
      url: this.data.get("uploadUrl")
    });
    this.registerHandlers();
  }

  disconnect() {
    this.dropzoneInstance.destroy();
  }

  get clickElement() {
    return this.hasClickableTarget ? this.clickableTarget : true;
  }

  registerHandlers() {
    this.dropzoneInstance.on("sending", this.onSendingHandler.bind(this));
    this.dropzoneInstance.on("success", this.onSuccessHandler.bind(this));
    this.dropzoneInstance.on("error", this.onErrorHandler.bind(this));
    this.dropzoneInstance.on("complete", this.onCompleteHandler.bind(this));
  }

  onSendingHandler(file, xhr, formData) {
    formData.append("upload_preset", this.data.get("uploadPreset"));
  }

  onSuccessHandler(file, response) {
    this.updateCollateral(response);
  }

  onErrorHandler(file, errorMessage, xhr) {
    console.log(errorMessage);
    console.log(xhr);
    setTimeout(() => {
      if (typeof errorMessage === "string") {
        alert(errorMessage);
      } else if (Object.prototype.hasOwnProperty.call(errorMessage, "error") && Object.prototype.hasOwnProperty.call(errorMessage.error, "message")) {
        alert(errorMessage.error.message);
      } else {
        alert("We encountered some issues when trying to uploade your image. Please try again.");
      }
    });
  }

  onCompleteHandler(file) {
    this.dropzoneInstance.removeFile(file);
  }

  async updateCollateral(response) {
    const method = this.data.get("method").toUpperCase();
    const headers = {
      "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content")
    };
    const body = new FormData();
    body.append("background_image[public_id]", response.public_id);
    body.append("background_image[version]", response.version.toString());
    body.append("background_image[signature]", response.signature);
    body.append("background_image[width]", response.width.toString());
    body.append("background_image[height]", response.height.toString());
    body.append("background_image[format]", response.format);
    body.append("background_image[bytes]", response.bytes.toString());
    await fetch(this.data.get("url"), { method, headers, body, credentials: "same-origin" });
    Turbolinks.clearCache();
    Turbolinks.visit(location.toString());
  }
}
