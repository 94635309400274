import Toastify from "toastify-js";

document.addEventListener("turbolinks:load", () => {
  const flashElements = document.getElementsByClassName("js-flash");

  Array.prototype.forEach.call(flashElements, (el) => {
    Toastify({
      text: el.dataset.jsFlashText,
      duration: 3000,
      close: true,
      className: el.dataset.jsFlashClass
    }).showToast();

    el.parentNode.removeChild(el);
  });
});
