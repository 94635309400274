import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.refresh();
  }

  connect() {
    this.refresh();
  }

  disconnect() {
    this.isExpanded = false;
  }

  get isExpanded() {
    return this.data.get("expanded") === "true";
  }

  set isExpanded(value) {
    this.data.set("expanded", value ? "true" : "false");
    this.refresh();
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  refresh() {
    if (this.isExpanded) {
      this.element.classList.add(this.data.get("classExpanded"));
    } else {
      this.element.classList.remove(this.data.get("classExpanded"));
    }
  }
}
