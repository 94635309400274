import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "cell" ]

  disableEditOther(e) {
    const { source } = e.detail;
    this.cellTargets.forEach((el) => {
      if (el.id !== source) {
        const requestCloseEvent = new Event("settings--list:request-disable-edit");
        el.dispatchEvent(requestCloseEvent);
      }
    });
  }
}
