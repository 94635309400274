import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "billingEmailCheckBox", "billingEmailInput" ]

  initialize() {
    this.refresh();
  }

  get useLoginEmail() {
    return this.billingEmailCheckBoxTarget.checked;
  }

  refresh() {
    if (this.useLoginEmail) {
      this.billingEmailInputTarget.value = "";
      this.billingEmailInputTarget.style.display = "none";
    } else {
      this.billingEmailInputTarget.style.display = "";
    }
  }
}
