import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "item", "showTrigger", "hideTrigger" ]

  connect() {
    this.renderItems();
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  get hidden() {
    return this.data.get("hidden") === "true";
  }

  set hidden(value) {
    this.data.set("hidden", value.toString());
    this.renderItems();
  }

  renderItems() {
    if (this.hidden) {
      this.itemTargets.forEach((item) => {
        item.style.display = "none";
      });
      this.showTriggerTarget.style.display = "";
      this.hideTriggerTarget.style.display = "none";
    } else {
      this.itemTargets.forEach((item) => {
        item.style.display = "";
      });
      this.showTriggerTarget.style.display = "none";
      this.hideTriggerTarget.style.display = "";
    }
  }
}
