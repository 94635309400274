import * as Sentry from "@sentry/browser";

const sentryDsnMetaTag = document.querySelector("meta[name=\"gd-sentry-dsn\"]");
if (typeof sentryDsnMetaTag !== "undefined" && sentryDsnMetaTag !== null) {
  const sentryOption = {
    dsn: sentryDsnMetaTag.getAttribute("content")
  };

  const sentryEnvMetaTag = document.querySelector("meta[name=\"gd-sentry-env\"]");
  if (typeof sentryEnvMetaTag !== "undefined" && sentryEnvMetaTag !== null) {
    Object.assign(sentryOption, {
      environment: sentryEnvMetaTag.getAttribute("content")
    });
  }

  Sentry.init(sentryOption);
}
