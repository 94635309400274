import { Controller } from "stimulus";
import Dropzone from "dropzone";
import { Spinner } from "spin.js";
import Turbolinks from "turbolinks";

export default class extends Controller {
  connect() {
    this.dropzoneInstance = new Dropzone(this.element, {
      createImageThumbnails: false,
      previewsContainer: false,
      acceptedFiles: this.data.get("acceptedFiles"),
      url: this.data.get("uploadUrl")
    });
    this.registerHandlers();
  }

  disconnect() {
    this.dropzoneInstance.destroy();
  }

  registerHandlers() {
    this.dropzoneInstance.on("addedfile", this.onAddedFileHandler.bind(this));
    this.dropzoneInstance.on("sending", this.onSendingHandler.bind(this));
    this.dropzoneInstance.on("success", this.onSuccessHandler.bind(this));
    this.dropzoneInstance.on("error", this.onErrorHandler.bind(this));
    this.dropzoneInstance.on("complete", this.onCompleteHandler.bind(this));
  }

  onAddedFileHandler() {
    this.element.style.position = "relative";
    this.element.appendChild(this.spinner);
  }

  onSendingHandler(file, xhr, formData) {
    formData.append("upload_preset", this.data.get("uploadPreset"));
  }

  onSuccessHandler(file, response) {
    this.updateCollateral(response);
  }

  onErrorHandler(file, errorMessage, xhr) {
    console.log(errorMessage);
    console.log(xhr);
    this.element.removeChild(this.spinner);
    setTimeout(() => {
      if (typeof errorMessage === "string") {
        alert(errorMessage);
      } else if (Object.prototype.hasOwnProperty.call(errorMessage, "error") && Object.prototype.hasOwnProperty.call(errorMessage.error, "message")) {
        alert(errorMessage.error.message);
      } else {
        alert("We encountered some issues when trying to uploade your image. Please try again.");
      }
    }, 500);
  }

  onCompleteHandler(file) {
    this.dropzoneInstance.removeFile(file);
  }

  get spinner() {
    if (typeof this._spinner === "undefined" || this._spinner === null) {
      const spinnerOptions = {
        radius: 60,
        length: 40,
        width: 15
      };
      const spinner = new Spinner(spinnerOptions).spin();
      const spinnerWrapper = document.createElement("div");
      spinnerWrapper.style.background = "rgba(0, 0, 0, 0.3)";
      spinnerWrapper.style.bottom = 0;
      spinnerWrapper.style.left = 0;
      spinnerWrapper.style.position = "absolute";
      spinnerWrapper.style.right = 0;
      spinnerWrapper.style.top = 0;
      spinnerWrapper.appendChild(spinner.el);

      this._spinner = spinnerWrapper;
    }

    return this._spinner;
  }

  async updateCollateral(response) {
    const method = this.data.get("method").toUpperCase();
    const headers = {
      "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content")
    };
    const body = new FormData();
    body.append("floorplan_image[public_id]", response.public_id);
    body.append("floorplan_image[version]", response.version.toString());
    body.append("floorplan_image[signature]", response.signature);
    body.append("floorplan_image[width]", response.width.toString());
    body.append("floorplan_image[height]", response.height.toString());
    body.append("floorplan_image[format]", response.format);
    body.append("floorplan_image[bytes]", response.bytes.toString());
    await fetch(this.data.get("url"), { method, headers, body, credentials: "same-origin" });
    Turbolinks.clearCache();
    Turbolinks.visit(location.toString());
  }
}

