import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "field", "previewContainer" ]

  connect() {
    this.reload();
  }

  async reload() {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    const response = await fetch(this.queryUrl, { headers, credentials: "same-origin" });
    const html = await response.text();
    this.previewContainerTarget.innerHTML = html;
  }

  get template() {
    return this.fieldTarget.value;
  }

  get url() {
    return this.data.get("url");
  }

  get queryUrl() {
    const params = `${encodeURIComponent("template")}=${encodeURIComponent(this.template)}`;
    return `${this.url}?${params}`;
  }
}
