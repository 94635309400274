import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "iframeParentElement", "iframeElement" ]

  connect() {
    this.reload();
  }

  get isHidden() {
    return this.data.get("hidden").toLowerCase() === "true";
  }

  set isHidden(value) {
    this.data.set("hidden", value ? "true" : "false");
    this.reload();
  }

  toggle() {
    this.isHidden = !this.isHidden;
  }

  reload() {
    if (this.isHidden) {
      this.element.classList.add("is-hidden");
    } else {
      this.element.classList.remove("is-hidden");
      const iframeElement = this.iframeElementTarget;
      this.iframeParentElementTarget.removeChild(iframeElement);
      this.iframeParentElementTarget.appendChild(iframeElement);
    }
  }
}
