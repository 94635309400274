import { Controller } from "stimulus";

export default class extends Controller {
  get delegate() {
    const delegateSelector = this.data.get("delegate-selector");
    return document.querySelector(delegateSelector);
  }

  add(event) {
    const tokenValue = event.currentTarget.dataset.templateEditorTokenValue;
    const didAddEvent = new CustomEvent("template-editor--token-button-group:did-add", { detail: { tokenValue } });
    this.delegate.dispatchEvent(didAddEvent);
  }
}
