import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "content" ]

  get step() {
    return 100;
  }

  scrollUp() {
    this.contentTarget.scrollBy({ top: -this.step, left: 0, behavior: "smooth" });
  }

  scrollDown() {
    this.contentTarget.scrollBy({ top: this.step, left: 0, behavior: "smooth" });
  }
}
