import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "newLink", "container", "formContainer" ]

  showNewForm(event) {
    event.preventDefault();

    if (!this.formContainerTarget.hasChildNodes()) {
      const newFormLoader = document.createElement("div");
      newFormLoader.dataset.controller = "partial-loader";
      newFormLoader.dataset.partialLoaderUrl = this.data.get("url");

      this.formContainerTarget.appendChild(newFormLoader);
    }

    this.newLinkTarget.style.display = "none";
    this.containerTarget.style.display = "";

    const openEvent = new CustomEvent("guests--new:open", { bubbles: true, detail: { source: null } });
    this.element.dispatchEvent(openEvent);
  }

  cancelNewForm() {
    this.newLinkTarget.style.display = "";
    this.containerTarget.style.display = "none";
  }
}
