import { Controller } from "stimulus";
import { Spinner } from "spin.js";

export default class extends Controller {
  connect() {
    this.addSpinner();

    this.load();

    if (this.data.has("refreshInterval")) {
      this.startRefreshing();
    }
  }

  disconnect() {
    this.stopRefreshing();
  }

  async load() {
    const headers = { "X-Requested-With": "XMLHttpRequest" };
    const response = await fetch(this.data.get("url"), { headers, credentials: "same-origin" });
    const html = await response.text();

    this.removeSpinner();

    this.element.innerHTML = html;
  }

  startRefreshing() {
    this.refreshTimerId = setInterval(() => {
      this.load();
    }, this.data.get("refreshInterval"));
  }

  stopRefreshing() {
    if (this.refreshTimerId) {
      clearInterval(this.refreshTimerId);
    }
  }

  addSpinner() {
    if (this.data.has("minHeight")) {
      this.element.style.height = `${this.data.get("minHeight")}px`;
      const spinnerOptions = {
        radius: 4,
        length: 4,
        width: 2
      };
      const spinner = new Spinner(spinnerOptions).spin();
      this.spinnerWrapper = document.createElement("div");
      this.spinnerWrapper.appendChild(spinner.el);
      this.spinnerWrapper.style.position = "absolute";
      this.spinnerWrapper.style.top = "50%";
      this.spinnerWrapper.style.left = "50%";
      this.spinnerWrapper.style.marginTop = "-8px";
      this.spinnerWrapper.style.marginLeft = "-8px";
      this.element.appendChild(this.spinnerWrapper);
    }
  }

  removeSpinner() {
    if (this.data.has("minHeight")) {
      this.element.style.height = "";
      if (this.spinnerWrapper) {
        this.element.removeChild(this.spinnerWrapper);
        this.spinnerWrapper = null;
      }
    }
  }
}
