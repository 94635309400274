export function ttgs_trimmedStringOrNull(stringOrNull) {
  return stringOrNull === null ? null : stringOrNull.trim();
}

export function ttgd_unique(array) {
  if (!Array.isArray(array)) {
    throw "ttgd_unique error: Argument is not an array";
  }

  return [...new Set(array)];
}

export function ttgd_compactUnique(array) {
  if (!Array.isArray(array)) {
    throw "ttgd_compactUnique error: Argument is not an array";
  }

  return ttgd_unique(array).filter((elem) => {
    return typeof elem !== "undefined" && elem !== null && elem !== "";
  });
}
