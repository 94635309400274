import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "toggleElement" ]

  initialize() {
    this.refresh();
  }

  connect() {
    this.refresh();
  }

  disconnect() {
    this.isActive = false;
  }

  get isActive() {
    return this.data.get("active") !== "false";
  }

  set isActive(value) {
    this.data.set("active", value.toString());
    this.refresh();
  }

  toggle() {
    this.isActive = !this.isActive;
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }

  refresh() {
    const activeClass = this.data.get("activeClass");
    if (this.isActive) {
      this.element.classList.add(activeClass);
      this.toggleElementTargets.forEach((el) => {
        el.classList.add(activeClass);
      });
    } else {
      this.element.classList.remove(activeClass);
      this.toggleElementTargets.forEach((el) => {
        el.classList.remove(activeClass);
      });
    }
  }
}
