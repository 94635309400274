let modalCount = 0;

function renderOverlay() {
  const overlay = document.querySelector(".modal-overlay");
  if (typeof overlay !== "undefined" && overlay !== null) {
    if (modalCount > 0) {
      overlay.style.display = "";
    } else {
      overlay.style.display = "none";
    }
  }
}

function findModal(modalName) {
  return document.querySelector(`[data-js-modal="${modalName}"]`);
}

function open(modal) {
  if (modal.style.display === "none") {
    modal.style.display = "";
    modalCount++;
  }
}

function close(modal) {
  if (typeof modal.style.display === "undefined" || modal.style.display === null || modal.style.display === "") {
    modal.style.display = "none";
    modalCount--;
  }
}

export function openModal(modalName) {
  open(findModal(modalName));
  renderOverlay();
}

export function closeModal(modalName) {
  close(findModal(modalName));
  renderOverlay();
}

export function closeAllModal() {
  Array.prototype.forEach.call(document.querySelectorAll("[data-js-modal]"), close);
  modalCount = 0;
  renderOverlay();
}

document.addEventListener("click", function(e) {
  const clickTarget = e.target;
  if (clickTarget.classList.contains("modal-close")) {
    const modal = clickTarget.closest("[data-js-modal]");
    if (typeof modal !== "undefined" && modal !== null) {
      close(modal);
      renderOverlay();
    }
  }
});

document.addEventListener("turbolinks:before-cache", closeAllModal);
