import { ttgs_trimmedStringOrNull, ttgd_compactUnique } from "./utils";
import Turbolinks from "turbolinks";
import Handsontable from "handsontable";

class Guest {
  constructor(handsontableRow) {
    this.salutation = ttgs_trimmedStringOrNull(handsontableRow[0]);
    this.namePrefix = ttgs_trimmedStringOrNull(handsontableRow[1]);
    this.name = ttgs_trimmedStringOrNull(handsontableRow[2]);
    this.nameSuffix = ttgs_trimmedStringOrNull(handsontableRow[3]);

    if (handsontableRow[4] === null) {
      this.expectedAttendance = 1;
    } else {
      const parsedValue = Number.parseInt(handsontableRow[4], 10);
      this.expectedAttendance = Number.isInteger(parsedValue) ? parsedValue : 1;
    }

    this.affiliation = ttgs_trimmedStringOrNull(handsontableRow[5]);

    const parsedTableName = ttgs_trimmedStringOrNull(handsontableRow[6]);
    if (Number.isInteger(Number.parseInt(parsedTableName, 10))) {
      this.tableName = `Table ${parsedTableName}`;
    } else {
      this.tableName = parsedTableName;
    }

    this.qrId = ttgs_trimmedStringOrNull(handsontableRow[7]);
    this.qrKey = ttgs_trimmedStringOrNull(handsontableRow[8]);
  }

  toCreateGuestJSONParams() {
    return {
      "guest": {
        "salutation": this.salutation,
        "name_prefix": this.namePrefix,
        "name": this.name,
        "name_suffix": this.nameSuffix,
        "expected_attendance": this.expectedAttendance,
        "affiliation": this.affiliation,
        "table_name": this.tableName,
        "qr_id": this.qrId,
        "qr_key": this.qrKey
      }
    };
  }
}

const defaultHeaders = [
  "Salutation",
  "Name Prefix",
  "Name",
  "Name Suffix",
  "Party Size",
  "Affiliation",
  "Table",
  "QR ID",
  "QR Key"
];

const template = [
  [ "Mr", "", "Soon", "启睿", "1", "Bride Family", "VIP Table" ],
  [ "Mr", "Jonathan", "Tan", "Yiong Soon", "1", "Groom's Colleague", "Table 21" ],
  [ "Mr & Mrs", "Steven", "Chan", "", "2", "NS Friend", "Table 3" ],
  [ "Ms", "Maria", "Cosulich", "", "1", "Hiking Group", "Table 7" ]
];

Handsontable.renderers.registerRenderer("custom.value.default", (instance, td, row, col, prop, value, cellProperties) => {
  if (instance.isEmptyRow(row) && row < 4) {
    td.style.color = "#ccc";
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, template[row][col], cellProperties]);
  } else {
    td.style.color = "";
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
  }
});

let handsontableInstance = null;

document.addEventListener("turbolinks:load", () => {
  const guestlist = document.getElementById("guestlist");
  if (guestlist !== null) {
    handsontableInstance = new Handsontable(guestlist, {
      width: guestlist.clientWidth,
      height: 257,
      minRows: 10,
      minCols: defaultHeaders.length,
      minSpareRows: 1,
      colWidths: (index) => {
        const fixedColWidths = [90, 110, 150, 110, 90, 210, 130, 80, 90];
        if (index < defaultHeaders.length) {
          return fixedColWidths[index];
        } else {
          return 150;
        }
      },
      rowHeaders: true,
      colHeaders: defaultHeaders,
      cells: () => {
        return {
          renderer: "custom.value.default"
        };
      },
      contextMenu: [
        "cut",
        "copy",
        "---------",
        "row_above",
        "row_below",
        "remove_row",
      ]
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  if (handsontableInstance !== null) {
    handsontableInstance.destroy();
    handsontableInstance = null;
  }
});

function createTables(targetUrl, tableNames) {
  const headers = {
    "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content"),
    "Content-Type": "application/json"
  };
  const body = JSON.stringify({
    tables: tableNames.map((tableName) => {
      return {
        name: tableName
      };
    })
  });
  return fetch(targetUrl, { method: "POST", headers, body, credentials: "same-origin" });
}

function createGuest(targetUrl, guest) {
  const headers = {
    "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content"),
    "Content-Type": "application/json"
  };
  return fetch(targetUrl, {
    method: "POST",
    headers,
    credentials: "same-origin",
    body: JSON.stringify(guest.toCreateGuestJSONParams())
  });
}

document.addEventListener("click", (e) => {
  const clickTarget = e.target;

  if (clickTarget.tagName === "BUTTON" && clickTarget.id === "guestlist-submit") {
    if (handsontableInstance !== null) {
      const tableColumn = handsontableInstance.getDataAtCol(6).map((value) => {
        return ttgs_trimmedStringOrNull(value);
      });
      const uniqueTables = ttgd_compactUnique(tableColumn);

      const guests = handsontableInstance.getData().filter((row) => {
        return !handsontableInstance.isEmptyRow(row);
      }).map((row) => {
        return new Guest(row);
      });

      const tableUrl = clickTarget.dataset.jsTableUrl;
      const processGuestUrl = clickTarget.dataset.jsGuestlistUrl;

      createTables(tableUrl, uniqueTables).then((response) => {
        return response.json();
      }).then(() => {
        return guests.reduce((previousPromise, guest) => {
          return previousPromise.then(() => {
            return createGuest(processGuestUrl, guest);
          });
        }, Promise.resolve());
      }).then(() => {
        Turbolinks.clearCache();
        Turbolinks.visit(location.toString());
      });
    }
  }
});
