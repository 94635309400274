import { Controller } from "stimulus";

export default class extends Controller {
  disconnect() {
    this.hideHelp();
  }

  showHelp() {
    this.element.classList.add("is-focus");
  }

  hideHelp() {
    this.element.classList.remove("is-focus");
  }
}
