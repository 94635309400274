import { Controller } from "stimulus";
import Quill from "quill/core";
import { TokenBlot } from "../editor/blots";
import { TemplateEncoder, TemplateDecoder } from "../editor/template_coder";
import Rails from "rails-ujs";

Quill.register(TokenBlot);

export default class extends Controller {
  static targets = [ "observer", "field", "editor", "editorContainer", "previewContainer" ]

  initialize() {
    this.textChangeHandler = this.textChangeHandler.bind(this);
    this.tabKeyBindingHandler = this.tabKeyBindingHandler.bind(this);
    this.enterKeyBindingHandler = this.enterKeyBindingHandler.bind(this);
    this.editorFocusHandler = this.editorFocusHandler.bind(this);
    this.editorBlurHandler = this.editorBlurHandler.bind(this);

    this.encoder = TemplateEncoder;
    this.decoder = TemplateDecoder;
  }

  connect() {
    this.editor = new Quill(this.editorTarget, {
      placeholder: this.placeholderText,
      modules: {
        keyboard: this.keyboardConfig()
      }
    });
    this.addEventListeners();

    const value = this.fieldTarget.value;
    const decodedValue = this.decoder.decode(value);
    this.editor.setContents(decodedValue, Quill.sources.SILENT);
    this.editor.history.clear();

    this.originalFieldType = this.fieldTarget.type;
    this.fieldTarget.type = "hidden";

    this.hidePreview();
  }

  disconnect() {
    this.fieldTarget.type = this.originalFieldType;
    this.removeEventListeners();
    this.editor = null;
    this.editorTarget.innerHTML = "";
  }

  addEventListeners() {
    this.editor.on("text-change", this.textChangeHandler);

    this.editorBody.addEventListener("focus", this.editorFocusHandler);
    this.editorBody.addEventListener("blur", this.editorBlurHandler);
  }

  removeEventListeners() {
    this.editor.off("text-change", this.textChangeHandler);

    this.editorBody.removeEventListener("focus", this.editorFocusHandler);
    this.editorBody.removeEventListener("blur", this.editorBlurHandler);
  }

  get placeholderText() {
    return this.data.get("placeholder-text") || "";
  }

  get editorClass() {
    return this.data.get("editor-class") || "";
  }

  get editorBody() {
    return this.editorTarget.querySelector(".ql-editor");
  }

  addToken(event) {
    const { tokenValue } = event.detail;
    const selection = this.editor.getSelection(true);
    const offset = this.insertToken(selection.index, tokenValue);
    this.editor.setSelection(selection.index + offset, 0, Quill.sources.USER);
  }

  insertToken(index, value) {
    this.editor.insertText(index, " ", Quill.sources.USER);
    this.editor.insertEmbed(index, "token", value, Quill.sources.USER);
    let offset = 2;
    if (index !== 0) {
      this.editor.insertText(index, " ", Quill.sources.USER);
      offset += 1;
    }
    return offset;
  }

  textChangeHandler(_delta, _oldDelta, source) {
    if (source === Quill.sources.USER) {
      const encodedValue = this.encoder.encode(this.editor.getContents());
      this.fieldTarget.value = encodedValue;
    }
  }

  keyboardConfig() {
    return {
      bindings: {
        tab: {
          key: "Tab",
          handler: this.tabKeyBindingHandler
        },
        enter: {
          key: "Enter",
          handler: this.enterKeyBindingHandler
        }
      }
    };
  }

  tabKeyBindingHandler() {

  }

  enterKeyBindingHandler() {
    Rails.fire(this.element.closest("form"), "submit");
  }

  editorFocusHandler() {
    this.editorTarget.classList.add("is-focus");
  }

  editorBlurHandler() {
    this.editorTarget.classList.remove("is-focus");
  }

  showPreview() {
    this.editorContainerTarget.classList.add("is-hidden");
    this.previewContainerTarget.classList.remove("is-hidden");

    const willShowPreviewEvent = new Event("template-editor:will-show-preview");
    this.observerTarget.dispatchEvent(willShowPreviewEvent);
  }

  hidePreview() {
    this.editorContainerTarget.classList.remove("is-hidden");
    this.previewContainerTarget.classList.add("is-hidden");
  }
}
