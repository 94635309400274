import Turbolinks from "turbolinks";
import { Spinner } from "spin.js";

async function updateTableCoordinate(targetUrl, posX = "", posY = "") {
  const headers = {
    "X-CSRF-Token": document.querySelector("meta[name=\"csrf-token\"]").getAttribute("content"),
    "Content-Type": "application/json"
  };
  const body = JSON.stringify({
    table: {
      x_coord: posX,
      y_coord: posY
    }
  });
  await fetch(targetUrl, { method: "PUT", headers, body, credentials: "same-origin" });
  Turbolinks.clearCache();
  Turbolinks.visit(location.toString());
}

document.addEventListener("click", function(e) {
  let clickTarget = e.target;

  if (typeof clickTarget.dataset.jsFloorplan === "undefined") {
    const closestTarget = clickTarget.closest("[data-js-floorplan]");
    if (typeof closestTarget !== "undefined" && closestTarget !== null) {
      clickTarget = closestTarget;
    } else {
      return;
    }
  }

  if (typeof clickTarget.dataset.jsFloorplan !== "undefined") {
    const overlayNode = document.createElement("div");
    overlayNode.classList.add("viewport-overlay");
    overlayNode.addEventListener("click", function clickHandler() {
      this.removeEventListener("click", clickHandler);
      this.parentNode.removeChild(this);
    });

    const spinnerOptions = {
      radius: 60,
      length: 40,
      width: 15
    };
    const spinner = new Spinner(spinnerOptions).spin();
    const spinnerWrapper = document.createElement("div");
    spinnerWrapper.style.height = "100%";
    spinnerWrapper.style.position = "relative";
    spinnerWrapper.style.width = "100%";
    spinnerWrapper.appendChild(spinner.el);
    overlayNode.appendChild(spinnerWrapper);

    document.body.appendChild(overlayNode);

    const floorplanImage = new Image();
    floorplanImage.addEventListener("load", function() {
      const idealWidth = this.width;
      const idealHeight = this.height;
      const clientHeight = 800;
      const clientWidth = clientHeight * idealWidth / idealHeight;
      this.height = clientHeight;
      this.width = clientWidth;
      this.classList.add("u-absolute");
      this.classList.add("cursor-highlight");
      this.style.left = "50%";
      this.style.marginLeft = `${-clientWidth / 2}px`;
      this.style.marginTop = `${-clientHeight / 2}px`;
      this.style.top = "50%";
      this.addEventListener("click", function(e) {
        const boundingRect = this.getBoundingClientRect();

        const posX = e.pageX - boundingRect.left - window.scrollX;
        const posY = e.pageY - boundingRect.top - window.scrollY;

        const scaledPosX = Math.floor(posX / clientWidth * idealWidth);
        const scaledPosY = Math.floor(posY / clientHeight * idealHeight);

        updateTableCoordinate(clickTarget.dataset.jsUrl, scaledPosX, scaledPosY);
      });

      spinner.stop();
      spinnerWrapper.appendChild(this);
    });

    floorplanImage.src = clickTarget.dataset.jsImageSrc;
  }
});
