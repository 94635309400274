import { Controller } from "stimulus";
import Rails from "rails-ujs";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = [ "form" ]

  onNameChange() {
    this.formTarget.addEventListener("ajax:success", () => {
      Turbolinks.clearCache();
      Turbolinks.visit(location.toString());
    });

    Rails.fire(this.formTarget, "submit");
  }
}
